@use './abstracts' as * ;
@use "sass:math";

* {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: $background-color;
  }

  div.cursors div {
    position: absolute;
    top: 300px;
    left: 300px;
    width: 32px;
    height: 32px;
    background-color: $green-details;
    border-radius: 50%;
    z-index: 10000;
    pointer-events: none;
    transform: translate(-50%, -50%);
    
    &:hover {
      display: none;
    }
  }

  a {
    color: inherit;
    font-family: $font-secondary;
    font-size: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }


    .btn {
      display: flex;
      font-size: 10px;
      padding: 8px;
      margin: 1.5px;
      justify-content: center;
      flex-direction: row;
      align-items: baseline;
    }

    .card-buttons {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: baseline;
    }

  h3 {
    font-family: $font-brand;
    padding-top: 25px;
    padding-bottom: 10px;;
  }

  .HomePage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
  }

  .navbar {
    justify-content: space-between ;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
    top: 10px;
    height: 50px;
    font-size: 20px;
    width: 100vw;
    z-index: 10;

    &-link-logo {
      display: flex;
      justify-content: left;
      margin-left: 100px;
    } 

    &-linky {
      display:flex;
        text-decoration: none;
        padding-left: 15px;
        
    }

    &-linklist{
      display:flex;
      justify-content: right;
      padding-right: 10vw;
    }


  }

  .contact-content {
    height: 85vh;

    &-socials {
      display: flex;
      justify-content: center;
      padding-top: 15px;
    }
   
  }
 
  .work { 
      &-card {
        font-family: $font-secondary;
        font-size: 14px;
        line-height: 1.3;
        padding-bottom: 25px;;
      }

      &-image {
        min-height: 226px;
        max-width: 100%;
             
      }

  }

  .studio-text{
    font-size: 90px;
    font-family: $font-brand;
    color: #000;
    display: flex;
    text-align: center;
    line-height: 1.2;
    justify-content: center;
    padding-top: 300px;
    padding-top: 100px;
    color: black;
  }

  .details{
    width: 80vw;
    margin: 25px;
  }

  .image-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.paragraph-text{
  font-family: $font-secondary;
  font-size: 16px;
  text-align: center;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 80px;
  
}

.myfooter {
  height: 30px;
  color: white;
  margin-top: 20px;
  margin-bottom: 8px;
  padding-bottom: 12px;;
  display: flex;
  justify-content: center;
  margin-top: 20vh;
}


@media (max-width: 899px) {
  .studio-text{font-size: 70px;}
}

@media (max-width: 599px) {
  .studio-text{font-size: 40px;}
  div.cursors div {display: none}
}



