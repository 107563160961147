

  @font-face {
    font-family: 'PPMonumentExtended-RegularItalic';
    src: local('PPMonumentExtended-Regular'), url('../../fonts/PPMonumentExtended-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'Louise-Regular';
    src: local('Louise-Regular'), url('../../fonts/Louise-Regular.otf') format('opentype');
  }

//VARIABLES
///typography

$font-secondary: 'PPMonumentExtended-RegularItalic', sans-serif;
// $font-title: 'NeueMetana-Bold';
$font-brand: 'Louise-Regular';
$font-body : 'PPMonumentExtended-RegularItalic', sans-serif;

//colors
$background-color:rgb(209, 190, 216);
$green-details: rgb(198, 215, 45);




